<template>
    <div class="topPhysical">
        <!-- 导航 -->
        <page-head title="TOP榜单 - 体测">
            <template #right>
                <div class="filter_box">
                    <select-style
                        :list="templateList"
                        v-model:value="currentTemplate"
                        @changeFn="changeFn($event, 'Template')"
                        skin="white"
                        label="title"
                        key="id"
                        class="template"
                    ></select-style>
                </div>
            </template>
        </page-head>
        <content-bg>
            <template #content>
                <div class="wrapper">
                    <div class="content-header">
                        <p class="title">{{ currentTemplate.templateName }}</p>
                        <div class="right_box">
                            <p class="tips">排序</p>
                            <select-style
                                :list="currentTemplate.physicalTemplateItemList"
                                v-model:value="currentTemplateItem"
                                @changeFn="changeFn($event, 'TemplateItem')"
                                skin="white"
                                label="itemName"
                                key="id"
                                class="sort"
                            ></select-style>
                            <select-style
                                :list="playerSexList"
                                v-model:value="currentSex"
                                @changeFn="changeFn($event, 'Sex')"
                                skin="white"
                                label="label"
                                key="value"
                                class="sort"
                            ></select-style>
                        </div>
                    </div>
                    <el-table
                        v-if="firstPlayerData.length"
                        :data="firstPlayerData"
                        class="first"
                        style="width: 100%"
                    >
                        <el-table-column prop="prop">
                            <template #header>
                                <span>姓名</span>
                            </template>
                            <template #default="scope">
                                <div class="player_wrapper">
                                    <div class="player_avatar_wrapper">
                                        <img
                                            class="avatar"
                                            :src="$utils.defaultAvatar(scope.row.playerAvatar)"
                                            alt=""
                                        />
                                        <img class="sex" :src="scope.row && scope.row.playerSex == 2 ? require('@i/team/sex_w.svg') : require('@i/team/sex_m.svg')" alt="" />
                                    </div>
                                    <span class="player_name">{{ scope.row.playerName }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-for="item in currentTemplate.physicalTemplateItemList"
                            :key="item.id"
                            :label="item.itemName"
                        >
                            <template #default="scope">
                                <span class="player_num_text akrobatRegular">
                                    {{ getTableText(item, scope.row) }}
                                </span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-table
                        :data="dataSource.showList"
                        :row-class-name="tableRowClassName"
                        empty-text="暂无数据"
                        style="width: 100%"
                    >
                        <el-table-column prop="prop">
                            <template #header>
                                <span>姓名</span>
                            </template>
                            <template #default="scope">
                                <div class="player_wrapper">
                                    <span class="player_num_text index akrobatRegular">
                                        {{ getTableIndex(scope.$index) }}
                                    </span>
                                    <div class="player_avatar_wrapper">
                                        <img
                                            class="avatar"
                                            :src="$utils.defaultAvatar(scope.row.playerAvatar)"
                                            alt=""
                                        />
                                        <img class="sex" :src="scope.row && scope.row.playerSex == 2 ? require('@i/team/sex_w.svg') : require('@i/team/sex_m.svg')" alt="" />
                                    </div>
                                    <span class="player_name">{{ scope.row.playerName }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-for="item in currentTemplate.physicalTemplateItemList"
                            :key="item.id"
                            :label="item.itemName"
                        >
                            <template #default="scope">
                                <span class="player_num_text akrobatRegular">
                                    {{ getTableText(item, scope.row) }}
                                </span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </template>
        </content-bg>
        <!-- 翻页 -->
        <pagination-com
            v-if="dataSource.showList && dataSource.showList.length > 0"
            class="pagination"
            :current-page="dataSource.page"
            :total-page="dataSource.totalPage"
            @changePage="changePage"
        />
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs, getCurrentInstance, onMounted } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
    name: 'topPhysical',
    setup() {
        const { proxy } = getCurrentInstance();
        const store = useStore();
        const state = reactive({
            templateList: [], // 模板列表
            currentTemplate: {}, // 当前模板
            currentTemplateItem: {}, // 当前排序规则
            playerSexList: [
                { label: '全部', value: 'all' },
                { label: '男', value: '1' },
                { label: '女', value: '2' },
            ],
            currentSex: { label: '全部', value: 'all' },
            firstPlayerData: [],
            copyDataSource: [],
            dataSource: {},
            pageSize: 8,
        });

        // 获取模板列表
        const getPhysicalTemplates = () => {
            const { id } = store.state.teamData;
            proxy.$server.getPhysicalTemplates({ teensCampId: id }).then((res) => {
                if (res.code === 200 && res.data && res.data.length) {
                    state.templateList = res.data;
                    state.currentTemplate = state.templateList[0];
                    state.currentTemplateItem = state.currentTemplate.physicalTemplateItemList[0];
                    getPhysicalListAnalysis();
                }
            });
        };

        // 获取体测数据
        const getPhysicalListAnalysis = () => {
            const { id } = store.state.teamData;
            let params = {
                teensCampId: id,
                physicalTemplateId: state.currentTemplate.id,
            };
            proxy.$server.getPhysicalListAnalysis(params).then((res) => {
                res.data = res.data || [];
                if (res.data.length) {
                    res.data.forEach((item) => {
                        item.data = item.data || [];
                        if (item.data.length < state.currentTemplate.physicalTemplateItemList.length) {
                            state.currentTemplate.physicalTemplateItemList.forEach((tempItem) => {
                                // 如果item.data的小项数据，少于templateItemList中的小项数据，则补齐item.data。
                                const bool = item.data.some(
                                    (dataItem) => dataItem.physicalTemplateItemId === tempItem.id
                                );
                                if (!bool) {
                                    item.data.push({
                                        physicalTemplateItemId: tempItem.id,
                                        data: '',
                                        score: '',
                                    });
                                }
                            });
                        }
                    });

                    state.copyDataSource = JSON.parse(JSON.stringify(res.data));
                    state.dataSource = proxy.$utils.paginationFn(res.data, 1, state.pageSize);

                    if (state.currentTemplate.physicalTemplateItemList.length) {
                        onTemplateItemChange(state.currentTemplateItem.id);
                    }
                } else {
                    state.copyDataSource = [];
                    state.dataSource = [];
                    state.firstPlayerData = [];
                }
            });
        };

        // 获取table行类名
        const tableRowClassName = ({ rowIndex }) => {
            if (rowIndex === 0) {
                return 'display_none';
            } else if (rowIndex < 10) {
                if (rowIndex % 2 !== 0) {
                    return 'custom_stripe_lt10';
                }
            } else {
                if (rowIndex % 2 !== 0) {
                    return 'custom_stripe_gt10';
                }
                return 'custom_gt10';
            }
            return '';
        };

        // 根据某个体测小项的数值进行排序
        const onTemplateItemChange = (e) => {
            const currentItem =
                state.currentTemplate.physicalTemplateItemList.find((item) => item.id === e) || {};
            const dataType = currentItem.type;
            // const sortByStandardForEvaluation = currentItem.standardForEvaluation?.length > 0 // 根据评分标准排序
            state.dataSource.showList.sort((a, b) => {
                const aData = a.data.find((item) => item.physicalTemplateItemId === e) || {};
                const bData = b.data.find((item) => item.physicalTemplateItemId === e) || {};
                const numAData = proxy.$utils.changeTimeByH(aData.data || '');
                const numBData = proxy.$utils.changeTimeByH(bData.data || '');
                // 注释的内容是根据标准排序，先留着。
                if (dataType === 'hms' || dataType == 'ms') {
                    // 时间类型排序
                    // if (sortByStandardForEvaluation) { // 如果有标准
                    // 	if (aData.score === bData.score) { // 如果分数相同，按照时间根据sort排序
                    // 		if (currentItem.sort === 'ascending') { // 分数相同按照时间升序
                    // 			return (!numAData) - (!numBData) || numAData - numBData
                    // 		} else { // 分数相同按照时间降序
                    // 			return numBData - numAData || (!numBData) - (!numAData)
                    // 		}
                    // 	} else { // 分数不同，按照分数降序
                    // 		return bData.score - aData.score || (!numBData) - (!numAData)
                    // 	}
                    // } else { // 没有标准，按照时间根据sort排序
                    if (currentItem.sort === 'ascending') {
                        return !numAData - !numBData || numAData - numBData;
                    } else {
                        return numBData - numAData || !numBData - !numAData;
                    }
                    // }
                } else {
                    // 数值排序
                    // if (sortByStandardForEvaluation) { // 有标准按照标准排序
                    // 	if (aData.score === bData.score) { // 如果分数相同，按照数值根据sort排序
                    // 		if (currentItem.sort === 'ascending') {
                    // 			return (!aData.data) - (!bData.data) || aData.data - bData.data
                    // 		} else {
                    // 			return bData.data - aData.data || (!bData.data) - (!aData.data)
                    // 		}
                    // 	} else { // 分数不同，按照分数降序
                    // 		return bData.score - aData.score || (!bData.data) - (!aData.data)
                    // 	}
                    // } else { // 没有标准，按照数值根据sort排序
                    if (currentItem.sort === 'ascending') {
                        return !aData.data - !bData.data || aData.data - bData.data;
                    } else {
                        return bData.data - aData.data || !bData.data - !aData.data;
                    }
                    // }
                }
            });
            if (state.dataSource.showList.length && state.dataSource.page === 1) {
                state.firstPlayerData = [state.dataSource.showList[0]];
            } else {
                state.firstPlayerData = [];
            }
        };

        // 根据性别筛选球员
        const onPlayerSexChange = (e) => {
            state.dataSource = {};
            if (e.value === 'all') {
                state.dataSource = proxy.$utils.paginationFn(
                    JSON.parse(JSON.stringify(state.copyDataSource)),
                    1,
                    state.pageSize
                );
            } else {
                state.dataSource = proxy.$utils.paginationFn(
                    state.copyDataSource.filter((item) => item.playerSex == e.value),
                    1,
                    state.pageSize
                );
            }
            // 筛选后重新排序
            onTemplateItemChange(state.currentTemplateItem.id);
        };

        const changeFn = (event, type) => {
            state['current' + type] = event;
            if (type === 'Template') {
                state.currentTemplateItem = state.currentTemplate.physicalTemplateItemList[0];
                getPhysicalListAnalysis();
            }
            if (type === 'TemplateItem') {
                onTemplateItemChange(event.id);
            }
            if (type === 'Sex') {
                onPlayerSexChange(event);
            }
        };

        const changePage = (page) => {
            state.dataSource = proxy.$utils.paginationFn(
                JSON.parse(JSON.stringify(state.copyDataSource)),
                page,
                state.pageSize
            );
            // 筛选后重新排序
            onTemplateItemChange(state.currentTemplateItem.id);
        };

        // 获取表格展示数据
        const getTableText = (templateItem, playerData) => {
            const obj = playerData.data.find((item) => item.physicalTemplateItemId === templateItem.id);
            return obj ? obj.data || '-' : '-';
        };

        // 获取表格index
        const getTableIndex = (index) => {
            if (state.dataSource.page === 1) {
                return index + 1;
            } else {
                return state.dataSource.page * state.pageSize + index;
            }
        };

        onMounted(() => {
            getPhysicalTemplates();
        });

        return {
            ...toRefs(state),
            changeFn,
            changePage,
            getTableText,
            tableRowClassName,
            onPlayerSexChange,
            getTableIndex,
        };
    },
});
</script>

<style lang="scss" scoped>
::v-deep .navigation-mian {
    width: 1410px;
    position: relative;
    z-index: 10;
}
::v-deep.pagination {
    position: absolute;
    left: 592px;
    top: 978px;
}
.filter_box {
    margin-left: auto;
    margin-right: 0;
    display: flex;
    .select {
        height: 78px;
        border-radius: 12px;
        ::v-deep .show {
            height: 78px;
            line-height: 78px;
        }
    }
    .template {
        width: 340px;
    }
}
.wrapper {
    padding: 0 25px;
}
.content-header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 86px;
    position: relative;
    z-index: 10;
    .title {
        font-size: 32px;
        color: #ffffff;
    }
    .right_box {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        display: flex;
        align-items: center;
        .tips {
            font-size: 24px;
            color: #ffffff;
            opacity: 0.6;
        }
        .sort {
            width: 200px;
            margin-left: 12px;
        }
    }
}
.first_player_box {
    display: table;
    box-sizing: border-box;
    width: 100%;
    height: 112px;
    padding: 0 16px;
    background: linear-gradient(93.06deg, #6950ff 36.63%, #9483ff 119.19%);
    border-radius: 12px;
    .first_player_wrapper {
        display: table-row;
    }
    .first_player_container {
        display: table-cell;
        vertical-align: middle;
        .player_wrapper {
            display: flex;
            align-items: center;
        }
        .first_player_name {
            margin-left: 12px;
            font-size: 22px;
            color: #ffffff;
        }
    }
    .first_player_value {
        display: table-cell;
        padding: 0 10px;
        box-sizing: border-box;
        vertical-align: middle;
        font-size: 30px;
        font-family: akrobatRegular;
        color: #fff;
    }
}
.el-table {
    background-color: transparent;
    &.first {
        ::v-deep .el-table__header-wrapper {
            display: none;
        }
        ::v-deep .el-table__body-wrapper {
            .el-table__row {
                background: linear-gradient(93.06deg, #6950ff 36.63%, #9483ff 119.19%);
                border-radius: 12px;
            }
            .el-table__cell {
                height: 112px;
                background-color: transparent;
            }
        }
        .player_avatar_wrapper {
            position: relative;
            width: 80px;
            height: 80px;
            border-radius: 12px;
            overflow: hidden;
            .avatar {
                width: 100%;
            }
            .sex {
                position: absolute;
                right: 1px;
                bottom: 1px;
                width: 32px;
                height: 32px;
            }
        }
        .player_name {
            margin-left: 16px;
        }
    }
    ::v-deep tr {
        background-color: transparent;
        &.display_none {
            display: none;
        }
        &:nth-child(2n) {
            td.el-table__cell {
                background-color: rgba(255, 255, 255, 0.06);
            }
        }
        .player_wrapper {
            display: flex;
            align-items: center;
        }
        .player_avatar_wrapper {
            width: 50px;
            height: 50px;
            border-radius: 10px;
            position: relative;
            overflow: hidden;
            .avatar {
                width: 100%;
            }
            .sex {
                position: absolute;
                right: 1px;
                bottom: 1px;
                width: 20px;
                height: 20px;
            }
        }
        .player_num_text {
            font-size: 24px;
            color: #ffffff;
            &.index {
                width: 40px;
            }
        }
        .player_name {
            margin-left: 12px;
            font-size: 22px;
            color: #ffffff;
        }
    }
    ::v-deep td.el-table__cell {
        border-bottom-color: rgba(255, 255, 255, 0.2);
    }
    ::v-deep th.el-table__cell {
        background-color: transparent;
        border-bottom-color: rgba(255, 255, 255, 0.2);
    }
    ::v-deep .el-table__inner-wrapper::before {
        display: none;
    }
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
    background-color: transparent;
}
</style>
